/**
 * About component
 *
 * Space for you to describe more about yourself.
 */

import React from "react";

/**
 * About background image
 *
 * Below is a sample image. Upload the image of your choice into the "images"
 * directory and import here for use. Then, set imageAltText to string that 
 * represents what you see in that image.
 *
 * Need an image? Check out https://unsplash.com to download a image you
 * freely use on your site.
 */
import image from "../images/motion-background.jpg";

const imageAltText = "purple and blue abstract background";

/**
 * Sort description that expands on your title on the Home component.
 */
const description =
  "Abdulrahman is a Data Scientist at BMW Group Plant Hams Hall, specializing in AI systems with a background in Computer and Data Science from Birmingham City University. He is fluent in Arabic and English, and is driven by a passion for computational theory, advanced algorithms, and efficient coding practices. Abdulrahman is resilient, growth-oriented, and dedicated to impactful learning.";

/**
 * List of some of skills or technologies you work on, are learning,
 * passionate about, or enjoy,
 */
const skillsList = [
  "Training AI models",
  "SQL (PostgreSQL/Oracle)",
  "CI/CD (Continuous Integration/Continuous Deployment)",
  "Data Engineering",
  "Programming (Python/Node.js)",
  "Data Visualization (e.g., Tableau, Power BI, Matplotlib)",
  "Machine Learning Frameworks (e.g., TensorFlow, PyTorch)",
  "Big Data Technologies (e.g., Hadoop, Spark)",
  "Cloud Platforms (e.g., AWS, GCP, Azure)",
  "Software Development Lifecycle (SDLC)",
  "API Development and Integration",
  "Web Development (HTML, CSS, JavaScript)",
  "DevOps Practices",
  "Project Management",
];

/**
 * Use this to give more information about what you are passionate about,
 * how you best work, or even a quote. This will help someone learn more
 * about you on a professional level.
 */
const detailOrQuote =
  "Abdulrahman is a passionate Data Scientist at BMW, driven by advanced algorithms and a relentless pursuit of growth and impactful learning.";

const About = () => {
  return (
    <section className="padding" id="about">
      <img className="background" src={image} alt={imageAltText} />
      <div
        style={{
          backgroundColor: "white",
          width: "50%",
          padding: "4rem",
          margin: "3rem auto",
          textAlign: "center",
        }}
      >
        <h2>About Myself</h2>
        <p className="large">{description}</p>
        <hr />
        <ul
          style={{
            textAlign: "left",
            columns: 1,
            fontSize: "1.25rem",
            margin: "2rem 3rem",
            gap: "3rem",
          }}
        >
          {skillsList.map((skill) => (
            <li key={skill}>{skill}</li>
          ))}
        </ul>
        <hr />
        <p style={{ padding: "1rem 3rem 0" }}>{detailOrQuote}</p>
      </div>
    </section>
  );
};

export default About;
